import { Chart, ChartDataset, registerables, Colors } from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { useEffect, useState } from 'react';
import API from '../../services/api-service';
import TokenService from '../../services/token';
import { Button, Col, DatePicker, Form, notification, Row, Select } from 'antd';
import dayjs from 'dayjs';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(...registerables, Colors, ChartDataLabels);

export default function StatisticsPage() {
  const user = TokenService.getUser();
  let startOfWeek = dayjs().startOf('day');
  console.log(startOfWeek.day());
  if (startOfWeek.day() !== 5) {
    // 5 represents Friday
    startOfWeek = startOfWeek.subtract((startOfWeek.day() + 2) % 7, 'day');
  }
  let previouseStartOfWeek = startOfWeek.add(-7, 'day');
  let previouseEndOfWeek = startOfWeek.add(-1, 'day');
  const [data, setData] = useState<any>({
    labels: [],
    datasets: [],
  });
  const [userOptions, setUserOptions] = useState([]);
  const [productCategoryOptions, setProductCategoryOptions] = useState<any>([]);
  const [chartFitlerForm] = Form.useForm();
  const getDefaultStatistics = async () => {
    // const error = await chartFitlerForm.validateFields();
    const value = chartFitlerForm.getFieldsValue();
    const hot = await getStatistics({
      saleIds: value?.saleIds ?? null,
      categoryIds: value?.categoryIds ?? null,
      contactDateFrom: previouseStartOfWeek.format('YYYY-MM-DD'),
      contactDateTo: previouseEndOfWeek.format('YYYY-MM-DD'),
      orderDateFrom: previouseStartOfWeek.format('YYYY-MM-DD'),
      orderDateTo: previouseEndOfWeek.format('YYYY-MM-DD'),
    });
    const warn = await getStatistics({
      saleIds: value?.saleIds ?? null,
      categoryIds: value?.categoryIds ?? null,
      contactDateFrom: previouseStartOfWeek.subtract(7 + 14, 'day').format('YYYY-MM-DD'),
      contactDateTo: previouseEndOfWeek.subtract(7, 'day').format('YYYY-MM-DD'),
      orderDateFrom: previouseStartOfWeek.format('YYYY-MM-DD'),
      orderDateTo: previouseEndOfWeek.format('YYYY-MM-DD'),
    });
    // const cold = await getStatistics({
    //   saleIds: value.saleIds,
    //   categoryIds: value.categoryIds,
    //   contactDateFrom: previouseStartOfWeek.format('YYYY-MM-DD'),
    //   contactDateTo: previouseEndOfWeek.format('YYYY-MM-DD'),
    //   orderDateFrom: previouseStartOfWeek.format('YYYY-MM-DD'),
    //   orderDateTo: previouseEndOfWeek.format('YYYY-MM-DD'),
    // });
    const labels = hot.map((item: any) => item.fullName);
    setData({
      labels,
      datasets: [
        {
          label: 'Nóng',
          data: hot.map((item: any) => {
            return item.cr * 100;
          }),
          borderColor: '#FF6384',
          backgroundColor: '#FFB1C1',
          barThickness: 30,
          maxBarThickness: 20,
          minBarLength: 2,
          borderWidth: 1,
        } as ChartDataset,
        {
          label: 'Ấm',
          data: warn.map((item: any) => {
            return item.cr * 100;
          }),
          borderColor: '#36A2EB',
          backgroundColor: '#9BD0F5',
          barThickness: 30,
          maxBarThickness: 20,
          minBarLength: 2,
          borderWidth: 1,
        } as ChartDataset,
      ],
    });
  };
  const getStatistics = async (query?: any) => {
    const response = await API.get('/api/statistics/cr', {
      params: query,
    });
    return response.data;
  };
  const getStatisticsReport = async (query?: any) => {
    const response = await API.get('/api/statistics/cr_report', {
      params: query,
    });
    return response;
  };
  const getUsers = async () => {
    const response = await API.get('/api/permissions/users');
    setUserOptions(
      response.data.map((user: any) => ({
        value: user.id,
        label: user.fullName,
        role: user.role,
      })),
    );
  };
  async function getCategories() {
    try {
      const response = await API.get('/api/products/categories');
      setProductCategoryOptions(
        response.data?.map((item: any) => ({
          value: item.id,
          label: item.name,
        })) ?? [],
      );
    } catch (err: any) {
      notification.error({
        message: 'Lỗi lấy dữ liệu',
        description: err.message,
      });
    }
  }

  const onReportFilter = async () => {
    try {
      await chartFitlerForm.validateFields();
      const value = chartFitlerForm.getFieldsValue();
      const body = {
        // saleIds: value.saleIds,
        // categoryIds: value.categoryIds,
        contactDateFrom: (value.contactDate[0] as dayjs.Dayjs).format('YYYY-MM-DD'),
        contactDateTo: (value.contactDate[1] as dayjs.Dayjs).format('YYYY-MM-DD'),
        orderDateFrom: (value.orderDate[0] as dayjs.Dayjs).format('YYYY-MM-DD'),
        orderDateTo: (value.orderDate[1] as dayjs.Dayjs).format('YYYY-MM-DD'),
      };
      const response = await getStatisticsReport({
        ...body,
      });
      const contentDisposition = response.headers['content-disposition'];
      let fileName = `Report ${body.contactDateFrom}.${body.contactDateTo}.csv`;
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
      }
      const csvBlob = new Blob([response.data], { type: 'text/csv' });
      const csvUrl = window.URL.createObjectURL(csvBlob);

      const link = document.createElement('a');
      link.href = csvUrl;
      link.setAttribute('download', `${fileName}`); // Set your desired filename

      // Simulate a click on the hidden anchor tag to start the download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Revoke the temporary URL to avoid memory leaks
      window.URL.revokeObjectURL(csvUrl);
    } catch (error: any) {
      notification.error({
        message: 'Vui lòng chọn ngày',
        description: error.message,
      });
    }
  };

  const onChartFilter = async () => {
    try {
      const error = await chartFitlerForm.validateFields();
      const value = chartFitlerForm.getFieldsValue();

      const result = await getStatistics({
        saleIds: value.saleIds,
        categoryIds: value.categoryIds,
        contactDateFrom: (value.contactDate[0] as dayjs.Dayjs).format('YYYY-MM-DD'),
        contactDateTo: (value.contactDate[1] as dayjs.Dayjs).format('YYYY-MM-DD'),
        orderDateFrom: (value.orderDate[0] as dayjs.Dayjs).format('YYYY-MM-DD'),
        orderDateTo: (value.orderDate[1] as dayjs.Dayjs).format('YYYY-MM-DD'),
      });

      const labels = result.map((item: any) => item.fullName);
      setData({
        labels,
        datasets: [
          {
            label: 'Tỉ lệ chuyển đổi',
            data: result.map((item: any) => {
              return item.cr * 100;
            }),
            barPercentage: 1,
            barThickness: 50,
            maxBarThickness: 50,
            minBarLength: 2,
            borderWidth: 1,
          } as ChartDataset,
        ],
      });
    } catch (error: any) {
      notification.error({
        message: 'Vui lòng chọn ngày',
        description: error.message,
      });
    }
  };

  useEffect(() => {
    // getDefaultStatistics();
    getUsers();
    getCategories();
  }, []);

  return (
    <div className="p-[16px]">
      <Row gutter={[10, 32]} className="pb-6">
        <Col span={24}>
          <Form
            form={chartFitlerForm}
            layout="horizontal"
            labelCol={{ xs: { span: 8 }, sm: { span: 8 }, md: { span: 8 } }}
            wrapperCol={{ xs: { span: 16 }, sm: { span: 16 }, md: { span: 16 } }}
            scrollToFirstError={true}
            labelAlign="left"
          >
            <Form.Item
              name="orderDate"
              label="Ngày mua hàng"
              rules={[{ required: true, message: 'Vui lòng chọn ngày' }]}
            >
              <DatePicker.RangePicker />
            </Form.Item>
            <Form.Item
              name="contactDate"
              label="Ngày nhận lead"
              rules={[{ required: true, message: 'Vui lòng chọn ngày' }]}
            >
              <DatePicker.RangePicker />
            </Form.Item>
            {/* <Form.Item name="saleIds" label="Sale">
              <Select options={userOptions} allowClear mode="multiple" />
            </Form.Item>
            <Form.Item name="categoryIds" label="Ngành hàng">
              <Select options={productCategoryOptions} allowClear mode="multiple" />
            </Form.Item> */}
          </Form>
          <Button onClick={onReportFilter} type="primary">
            Thống kê
          </Button>
        </Col>
      </Row>
      {/* <Bar
        options={{
          plugins: {
            title: {
              display: false,
              text: 'Tỉ lệ chuyển đổi',
              padding: {
                bottom: 20,
              },
            },
            colors: {
              enabled: true,
            },
            tooltip: {},
            datalabels: {
              anchor: 'end', // Position datalabels at the end of the bar
              align: 'top', // Align datalabels to the top
              offset: 2, // Adjust the offset between the datalabels and the bars
              formatter: function (value: number, context) {
                return `${value.toFixed(1)} %`; // Display the value within the column
              },
            },
          },
          responsive: true,
          interaction: {
            mode: 'index' as const,
            intersect: false,
          },
          scales: {
            x: {},
            y: {
              max: 100,
            },
          },
        }}
        data={data}
        id="statistics"
      /> */}
    </div>
  );
}
