export const PERMISSION_MODE_OPTIONS = [
  {
    label: 'Audit',
    value: 1,
  },
  {
    label: 'Dò bài',
    value: 2,
  },
];
