export const DEMAND_OPTIONS = [
  {
    value: 0,
    label: 'Không có nhu cầu',
  },
  {
    value: 1,
    label: 'Có nhu cầu',
  },
  {
    value: 2,
    label: 'Rác',
  },
];

export const LEAD_HOT_DATE = {
  HOT: 7,
  WARM: 30,
  COLD: 60,
};
export const LEAD_STATUS = [
  {
    value: 0,
    label: 'Mới',
  },
  {
    value: 1,
    label: 'FL lần 1',
  },
  {
    value: 2,
    label: 'FL lần 2',
  },
  {
    value: 3,
    label: 'Đã chốt',
  },
  {
    value: 4,
    label: 'Đã lên đơn',
  },
  {
    value: 5,
    label: 'Từ chối',
  },
];
