import TokenService from '../../services/token';
import API from '../../services/api-service';
import { useEffect, useState } from 'react';
import { Button, DatePicker, Form, Input, Modal, notification, Popconfirm, Row, Select, Table, Tag } from 'antd';
import dayjs from 'dayjs';
import { PERMISSION_MODE_OPTIONS } from 'src/constants/permission.constants';
export default function PermissionPage() {
  const user = TokenService.getUser();
  const [permissions, setPermissions] = useState([]);
  const [openPermissionModal, setOpenPermissionModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [userOptions, setUserOptions] = useState([]);
  const [productCategoryOptions, setProductCategoryOptions] = useState<any>([]);
  const [permissionForm] = Form.useForm();
  const getPermissions = async () => {
    const response = await API.get('/api/permissions');
    setPermissions(response.data);
  };
  const getUsers = async () => {
    const response = await API.get('/api/permissions/users');
    setUserOptions(
      response.data.map((user: any) => ({
        value: user.id,
        label: user.fullName,
        role: user.role,
      })),
    );
  };

  const onGrantPermission = async () => {
    try {
      debugger;
      await permissionForm.validateFields();
      const data = permissionForm.getFieldsValue();
      const submitData: any = {
        userId: data.userId,
        saleIds: data.saleIds,
        note: data.note,
        categoryIds: data.categoryIds,
        mode: data.mode,
      };
      if (data.mode == 1) {
        submitData.contactLeadFrom = (data.contactLead[0] as dayjs.Dayjs).format('YYYY-MM-DD');
        submitData.contactLeadTo = (data.contactLead[1] as dayjs.Dayjs).format('YYYY-MM-DD');
        submitData.endDate = (data.endDate as dayjs.Dayjs).format('YYYY-MM-DD');
      }
      const response = await API.post('/api/permissions/grant', submitData);
      if (response.status != 200) {
        throw '';
      }
      permissionForm.resetFields();
      setOpenPermissionModal(false);
      getPermissions();
    } catch (error: any) {
      notification.error({
        message: 'Cấp quyền không thành công',
        description: error.message,
      });
    }
  };

  const revokePermission = async (permissionId: number) => {
    try {
      const response = await API.post('/api/permissions/revoke', {
        permissionId: permissionId,
      });
      if (response.status == 200) {
        notification.success({
          message: 'Đã thu hồi quyền',
        });
      } else {
        throw new Error(response.data.message);
      }
      getPermissions();
    } catch (error: any) {
      notification.error({
        message: 'Thu hồi quyền không thành công!',
        description: error.message,
      });
    }
  };
  async function getCategories() {
    try {
      const response = await API.get('/api/products/categories');
      setProductCategoryOptions(
        response.data?.map((item: any) => ({
          value: item.id,
          label: item.name,
        })) ?? [],
      );
    } catch (err: any) {
      notification.error({
        message: 'Lỗi lấy dữ liệu',
        description: err.message,
      });
    }
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 8 },
      sm: { span: 8 },
      md: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 16 },
      sm: { span: 16 },
      md: { span: 16 },
    },
  };
  useEffect(() => {
    getUsers();
    getPermissions();
    getCategories();
  }, []);
  return (
    <div className="md:p-8 sm:p-4 sm:pt-12">
      <Row gutter={[0, 32]} className="pb-6">
        <Button
          size="large"
          type="primary"
          onClick={() => {
            setOpenPermissionModal(true);
          }}
        >
          Phân quyền
        </Button>
      </Row>
      <Row gutter={[0, 32]} className="pb-6">
        <Table
          dataSource={permissions}
          columns={[
            {
              title: 'ID',
              dataIndex: 'id',
            },
            {
              title: 'Email',
              dataIndex: 'email',
            },
            {
              title: 'Người nhận quyền',
              render: (record: any) => {
                return <>{record.user.fullName}</>;
              },
            },
            {
              title: 'Từ',
              render: (record: any) => {
                return <>{dayjs(record.contactLeadFrom).format('DD-MM-YYYY')}</>;
              },
            },
            {
              title: 'Đến',
              render: (record: any) => {
                return <>{dayjs(record.contactLeadTo).format('DD-MM-YYYY')}</>;
              },
            },
            {
              title: 'Ghi chú',
              dataIndex: 'note',
            },
            {
              title: 'Ngày hết hiệu lực',
              dataIndex: 'endDate',
              render: value => {
                return <>{dayjs(value).format('DD-MM-YYYY')}</>;
              },
            },
            {
              title: 'Ngành hàng',
              render: (record: any) => {
                return <>{record.categories?.map((c: any) => <Tag>{c.name}</Tag>)}</>;
              },
            },
            {
              title: 'Dữ liệu sales',
              render: (record: any) => {
                return <>{record.sales?.map((c: any) => <Tag>{c.fullName}</Tag>)}</>;
              },
            },
            {
              title: '',
              render: (record: any) => {
                return (
                  <>
                    {record.status == 1 ? (
                      <Popconfirm
                        title="Thu hồi quyền đã cấp ?"
                        okText="Có"
                        cancelText="Hủy"
                        onConfirm={e => revokePermission(record.id)}
                      >
                        <Button type="dashed" danger>
                          Thu hồi
                        </Button>
                      </Popconfirm>
                    ) : (
                      <Tag color="magenta">Hết hiệu lực</Tag>
                    )}
                  </>
                );
              },
            },
          ]}
        />
        <Modal
          open={openPermissionModal}
          onCancel={() => setOpenPermissionModal(false)}
          title="Cấp quyền truy cập lead"
          width={'60%'}
          okText="Cấp quyền"
          cancelText="Hủy"
          onOk={() => onGrantPermission()}
        >
          <div className="p-6">
            <Form form={permissionForm} scrollToFirstError={true} labelAlign="left" {...formItemLayout}>
              <Form.Item
                name="userId"
                label="Tài khoản nhận quyền"
                rules={[{ required: true, message: 'Vui lòng chọn người nhận quyền' }]}
              >
                <Select options={userOptions} />
              </Form.Item>
              <Form.Item name="mode" label="Mode" rules={[{ required: true }]}>
                <Select options={PERMISSION_MODE_OPTIONS} />
              </Form.Item>
              <Form.Item noStyle shouldUpdate={true}>
                {({ getFieldValue }) => {
                  return [1].includes(getFieldValue('mode')) ? (
                    <>
                      <Form.Item
                        name="contactLead"
                        label="Khoảng thời gian"
                        rules={[{ required: true, message: 'Vui lòng chọn ngày' }]}
                      >
                        <DatePicker.RangePicker />
                      </Form.Item>
                      <Form.Item
                        name="endDate"
                        label="Ngày hết hiệu lực"
                        rules={[{ required: true, message: 'Vui lòng chọn ngày' }]}
                      >
                        <DatePicker />
                      </Form.Item>
                    </>
                  ) : null;
                }}
              </Form.Item>

              <Form.Item name="note" label="Ghi chú">
                <Input.TextArea />
              </Form.Item>
              <Form.Item name="categoryIds" label="Nhận theo ngành hàng">
                <Select options={productCategoryOptions} mode="multiple" />
              </Form.Item>
              <Form.Item name="saleIds" label="Nhận theo sale">
                <Select options={userOptions.filter((user: any) => user.role == 3)} mode="multiple" />
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </Row>
    </div>
  );
}
